import React, { VoidFunctionComponent } from 'react';
import { DynamicGrid, Text, breakpoints } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';

const MainContainer = styled.div`
    height: 218px;
    background-color: #f0f0f5;
    margin-top: 100px;
    margin-right: 10px;

    ${breakpoints.xs} {
        height: 441px;
    }

    ${breakpoints.sm} {
        height: 485px;
        margin-top: -29px;
    }

    ${breakpoints.md} {
        height: 218px;
        margin-bottom: 222px;
    }

    ${breakpoints.md2} {
        margin: 24px 0px 0px;
        height: 218px;
    }

    ${breakpoints.lg} {
        margin: 24px 0px 0px;
        height: 218px;
    }
`;

const ContentContainer = styled.div`
    padding-top: 24.5px;
    padding-left: 16px;
`;

const RoomImage = styled.img`
    margin-left: 19px;
    margin-top: -30px;

    ${breakpoints.xs} {
        margin-left: 19px;
        margin-top: 0px;
        width: 85%;
    }

    ${breakpoints.sm} {
        margin-left: 29px;
        margin-top: 11px;
        width: 85%;
    }

    ${breakpoints.md} {
        margin-left: 73px;
        margin-top: -30px;
        width: 80%;
    }

    ${breakpoints.md2} {
        margin-left: 29px;
        margin-top: 0px;
        width: 90%;
    }

    ${breakpoints.lg} {
        margin-left: 19px;
        margin-top: -30px;
    }
`;

export interface RoomProps {
    title: string;
    subTitle: string;
    image: string;
}

export const Room: VoidFunctionComponent<RoomProps> = ({ title, subTitle, image }) => (
    <MainContainer>
        <DynamicGrid gapSize={0} maxColumns={2} minWidth={18}>
            <ContentContainer>
                <Text size={4} weight={'heavy'}>
                    {title}
                </Text>
                <Text size={3} as='p'>
                    {subTitle}
                </Text>
            </ContentContainer>
            <RoomImage src={image} />
        </DynamicGrid>
    </MainContainer>
);
