import React, { VoidFunctionComponent } from 'react';
import { Container } from 'components/Main';
import { Button } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import RouterSpeedTest from './routerSpeedTest';

const MainContainer = styled.div`
  height: auto;
  display: grid;
  grid-template-rows: 1fr auto;
  position: relative;
`;

const buttonRightGroup = (
  <>
    <Button variant="outline" color="secondary">
      Skip
    </Button>
    <Button color="secondary">Continue</Button>
  </>
);
const RouterScan: VoidFunctionComponent = () => {
  return (
    <MainContainer>
      <Container
        steps={['complete', 'inprogress', 'incomplete']}
        footer={{
          onBack: true,
          buttonRightGroup,
        }}
      >
        <RouterSpeedTest />
      </Container>
    </MainContainer>
  );
};

export default RouterScan;
