import React, { FC } from 'react';
import styled from 'styled-components';
import { breakpoints, Text } from '@soluto-private/mx-asurion-ui-react-v3';

const Header = styled.div`
    line-height: 120%;
    margin: 128px auto 24px;
    width: 387px;

    ${breakpoints.xs} {
        width: auto;
        text-align: center;
        margin: 16px auto 24px;
        font-size: 32px;
    }

    ${breakpoints.smOnly} {
        text-align: center;
        max-width: 311px;
        margin: 16px auto 24px;
    }

    ${breakpoints.mdOnly} {
        text-align: center;
        width: auto;
        margin: 16px auto 24px;
    }

    ${breakpoints.md2Only} {
        margin: 128px auto 24px;
        max-width: 387px;
    }
`;

const SubHeader = styled.div`
    line-height: 130%;

    ${breakpoints.smOnly} {
        text-align: center;
    }

    ${breakpoints.mdOnly} {
        text-align: center;
    }

    ${breakpoints.md2Only} {
        margin-top: 8px;
    }
`;

export const GettingStartedHeader: FC = () => (
    <Header data-testid='Aside_Header'>
        <Text as='h2' size={5} weight='feather'>
            Your expert is standing-by
        </Text>
        <SubHeader>
            <Text as='h4' size={3}>
                Here&apos;s how to get started:
            </Text>
        </SubHeader>
    </Header>
);
