import React, { VoidFunctionComponent } from 'react';
import { Container } from 'components/Main';
import styled from 'styled-components';
import { Button } from '@soluto-private/mx-asurion-ui-react-v3';
import { updateHeader } from '@soluto-private/mx-app-header';
import { useNavigate } from 'react-router-dom';
import RouterSpeedTestQuestionPage from './routerSpeedTestQuestionPage';

const MainContainer = styled.div`
    display: grid;
    grid-template-rows: 1fr auto;
`;

const buttonRightGroup = <Button color='secondary'>Continue</Button>;

const RouterSpeedTestQuestion: VoidFunctionComponent = () => {
    const navigate = useNavigate();
    updateHeader({
        view: 'service-view',
        menu: true,
        onBack: () => navigate(-1), // onBack func not working...
        subNavConfig: {
            showBackButton: true,
            showPartnerLogo: true,
        },
    });

    return (
        <MainContainer>
            <Container
                steps={['complete', 'inprogress', 'incomplete']}
                footer={{
                    onBack: true,
                    buttonRightGroup,
                }}
            >
                <RouterSpeedTestQuestionPage />
            </Container>
        </MainContainer>
    );
};

export default RouterSpeedTestQuestion;
