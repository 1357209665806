import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { GettingStartedHeader } from './GettingStartedHeader';
import { GettingStartedExperts } from './GettingStartedExperts';
import {
    breakpoints,
    ProgressStepperStep,
    ProgressStepper,
    ProgressStepperStepState,
} from '@soluto-private/mx-asurion-ui-react-v3';

const GettingStartedContainer = styled.aside`
    grid-area: sidebar;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    ${breakpoints.xs} {
        background-color: #fff;
        border: 1px solid #a5aaaf;
        border-radius: 8px;
        margin: 16px;
        height: 482px;
    }

    ${breakpoints.smOnly} {
        background-color: #fff;
        border: 1px solid #a5aaaf;
        border-radius: 8px;
        margin: 16px;
        height: 482px;
    }

    ${breakpoints.mdOnly} {
        background-color: #fff;
        border: 1px solid #a5aaaf;
        border-radius: 8px;
        margin: 16px;
        height: 482px;
    }

    ${breakpoints.md2Only} {
        background-color: #f0f0f5;
        border: none;
        border-radius: 0px;
        margin: 0;
        height: 100vh;
        position: sticky;
        top: 0;
        bottom: 0;
    }

    ${breakpoints.lg} {
        background-color: #f0f0f5;
        border: none;
        height: 100vh;
        position: sticky;
        top: 0;
        bottom: 0;
    }
`;

const ProgressStepperWrapper = styled.div`
    margin: 0 auto;
    padding: 0 16px;

    ${breakpoints.sm} {
        max-width: 311px;
    }

    ${breakpoints.md} {
        max-width: 311px;
    }

    ${breakpoints.xlOnly} {
        max-width: 387px;
    }
`;

const CustomProgressStepper = styled(ProgressStepper)`
    h2 {
        max-width: unset;
    }
`;

const stepTitles: string[] = ['Run a test on your internet', 'Answer a few questions', 'Connect with your expert'];

export interface GettingStartedProps {
    steps: [ProgressStepperStepState, ProgressStepperStepState, ProgressStepperStepState];
}

export const GettingStarted: FC<GettingStartedProps> = ({ steps }) => {
    const finalSteps: ProgressStepperStep[] = useMemo(
        () =>
            steps.map((step, i) => ({
                state: step,
                stepTitle: stepTitles[i],
            })),
        [steps]
    );
    return (
        <GettingStartedContainer data-testid='Aside_Container'>
            <GettingStartedHeader />
            <ProgressStepperWrapper>
                <CustomProgressStepper isPacked steps={finalSteps} orientation='vertical' size='medium' />
            </ProgressStepperWrapper>
            <GettingStartedExperts />
        </GettingStartedContainer>
    );
};
