import React, { FC } from 'react';
import styled from 'styled-components';
import { AsurionExperts, AsurionExpertsMobile } from './assets';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react-v3';

const Image = styled.div`
    left: 15px;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;

    ${breakpoints.xs} {
        background-image: url(${AsurionExpertsMobile});
        position: initial;
        width: 344px;
        height: 480px;
    }

    ${breakpoints.smOnly} {
        background-image: url(${AsurionExpertsMobile});
        width: 344px;
        position: initial;
        height: 480px;
    }

    ${breakpoints.mdOnly} {
        width: 344px;
        background-image: url(${AsurionExpertsMobile});
        position: initial;
        height: 480px;
    }

    ${breakpoints.md2Only} {
        width: 448px;
        background-image: url(${AsurionExperts});
    }

    ${breakpoints.lg} {
        width: 584px;
        height: 440px;
        background-image: url(${AsurionExperts});
    }
`;

export const GettingStartedExperts: FC = () => <Image> </Image>;
