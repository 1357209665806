import { IWixiSession, WixiSession } from '@soluto-private/wixi-web-sdk';
import { useEffect, useState } from 'react';

export const useWixiSession = (): IWixiSession => {
    const [session, setSession] = useState<IWixiSession>({});

    useEffect(() => {
        WixiSession.onSessionChange = (wixiSession: IWixiSession) => setSession(wixiSession);

        return () => {
            WixiSession.onSessionChange = undefined;
        };
    }, []);

    return session;
};
