import React, { FC, ReactNode } from 'react';
import { breakpoints, ButtonGroup, Text } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';

const Wrapper = styled.div`
    margin-top: 40px;
    position: absolute;
    width: 100%;
    bottom: -250px;

    @media (max-width: 560px) {
        bottom: -320px;
    }

    ${breakpoints.sm} {
        margin-top: 200px;
    }

    ${breakpoints.md} {
        margin-top: 140px;
    }

    ${breakpoints.md2} {
        margin-top: 40px;
    }

    ${breakpoints.lg} {
        margin-top: 40px;
    }
`;

const ButtonGroupWrapper = styled.div`
    border-top: 1px solid #e6e6eb;
    border-top: 1px solid #e6e6eb;
    padding: 16px 12px;

    button {
        max-width: 190px;
    }
`;

const BottomBar = styled.div`
    background: rgba(248, 248, 248, 0.82);
    box-shadow: 0px -0.3px 0px #aeaeb4;
    backdrop-filter: blur(29.9px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px;
`;

type FooterMobile = {
    buttonGroup?: ReactNode;
};

const FooterMobile: FC<FooterMobile> = ({ buttonGroup }) => (
    <Wrapper>
        <ButtonGroupWrapper>
            <ButtonGroup hasFullWidthButtons alignment='center'>
                {buttonGroup}
            </ButtonGroup>
        </ButtonGroupWrapper>
        <BottomBar>
            <Text color='rgba(0, 0, 0, 0.5)' size={0}>
                my.asurion.com
            </Text>
        </BottomBar>
    </Wrapper>
);

export default FooterMobile;
