import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'components/Main';
import {
  CheckboxSelectionCard,
  DynamicGrid,
  Text,
  Button,
  breakpoints,
} from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import { updateHeader } from '@soluto-private/mx-app-header';
import QuestionTextWrapper from './questionTextWrapper';
import {
  streamingIcon,
  streaming4kIcon,
  gamingIcon,
  downloadingUploadingIcon,
  callingIcon,
  noneOfTheseIcon,
} from './assets';
import { useCheckIfMobileSize } from 'hooks/useCheckIfMobileSize';

const Wrapper = styled.div`
  gap: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${breakpoints.xs} {
    padding: 0px 16px;
    margin-bottom: 48px;
  }

  ${breakpoints.sm} {
    padding: 0px 16px;
    margin-bottom: 48px;
  }

  ${breakpoints.md} {
    padding: 0px 16px;
    margin-bottom: 48px;
  }

  ${breakpoints.md2} {
    padding: 0px 48px;
    margin-top: 46px;
  }

  ${breakpoints.lg} {
    padding: 0px 48px;
    margin-top: 46px;
  }
`;

const CheckboxCard = styled(CheckboxSelectionCard)`
  position: relative;
  overflow: hidden;
  height: 168px;

  > div {
    position: absolute;
    right: 0;
    padding: 0;
  }
`;

const CheckboxInner = styled.div`
  display: flex;
  width: 100%;
  height: 140px;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  gap: 8px;
`;

const CheckboxTitle = styled(Text)`
  text-align: center;
`;

const CustomGrid = styled(DynamicGrid)`
  width: 100%;
`;

const CheckboxImg = styled.img`
  width: 84px;
  height: 84px;
`;

type CustomCheckboxProps = {
  checked: boolean;
  onChange: () => void;
  icon: string;
  title: string;
  subtitle?: string;
};

const Checkbox: FC<CustomCheckboxProps> = ({
  checked,
  onChange,
  icon,
  title,
  subtitle,
}) => (
  <CheckboxCard checked={checked} onChange={onChange}>
    <CheckboxInner>
      <CheckboxImg alt={title} src={icon} />
      <CheckboxTitle>
        <Text as="p" style={{ padding: '0 10px' }}>
          {title}
        </Text>
        <Text as="p" color="#333F48">
          {subtitle}
        </Text>
      </CheckboxTitle>
    </CheckboxInner>
  </CheckboxCard>
);

type CheckboxKeys =
  | 'streaming'
  | '4kstreaming'
  | 'gaming'
  | 'downloadingUploading'
  | 'calling'
  | 'noneOfThese';

interface CheckboxesType
  extends Omit<CustomCheckboxProps, 'onChange' | 'checked'> {
  key: CheckboxKeys;
}

const checkboxes: CheckboxesType[] = [
  {
    key: 'streaming',
    icon: streamingIcon,
    title: 'Streaming',
    subtitle: '(Netflix, Youtube...)',
  },
  {
    key: '4kstreaming',
    icon: streaming4kIcon,
    title: '4k Streaming',
    subtitle: '(example?)',
  },
  {
    key: 'gaming',
    icon: gamingIcon,
    title: 'Gaming',
    subtitle: '(Xbox®, PlayStation®)',
  },
  {
    key: 'downloadingUploading',
    icon: downloadingUploadingIcon,
    title: 'Downloading or uploading large files',
  },
  {
    key: 'calling',
    icon: callingIcon,
    title: 'Video or Face-time calls',
  },
  {
    key: 'noneOfThese',
    icon: noneOfTheseIcon,
    title: 'None of these',
  },
];

const Question1: FC = () => {
  const navigate = useNavigate();
  const isMobileSize = useCheckIfMobileSize();
  const [selected, setSelected] = useState<CheckboxKeys[]>([]);

  updateHeader({
    view: 'service-view',
    menu: true,
    onBack: () => navigate(-1), // onBack func not working...
    subNavConfig: {
      showBackButton: true,
      showPartnerLogo: true,
    },
  });

  const onSetSelected = (key: CheckboxKeys) => {
    if (selected.includes(key)) {
      const newSelected = selected.filter((item) => item !== key);
      setSelected(newSelected);
    } else {
      setSelected([...selected, key]);
    }
  };

  const buttonRightGroup = (
    <>
      <Button variant="outline" color="secondary">
        Skip
      </Button>
      <Button color="secondary">Continue</Button>
    </>
  );

  return (
    <Container
      steps={['complete', 'inprogress', 'incomplete']}
      footer={{
        onBack: true,
        buttonRightGroup,
      }}
    >
      <Wrapper>
        <QuestionTextWrapper
          question={1}
          title="Which of these activities does your household do online?"
          subtitle="Some activities require more effort from your network."
        />
        <CustomGrid autoFit={false} minWidth={isMobileSize ? 8.5 : 12}>
          {checkboxes.map((item) => (
            <Checkbox
              key={item.key}
              checked={selected.includes(item.key)}
              icon={item.icon}
              title={item.title}
              subtitle={item.subtitle}
              onChange={() => onSetSelected(item.key)}
            />
          ))}
        </CustomGrid>
      </Wrapper>
    </Container>
  );
};

export default Question1;
