import React, { FC } from 'react';
import styled from 'styled-components';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react-v3';
import { GettingStarted, GettingStartedProps } from 'components/Aside';
import Footer, { FooterProps } from './Footer';
import { updateFooter } from '@soluto-private/mx-app-footer';
import FooterMobile from './FooterMobile';
import { useCheckIfMobileSize } from 'hooks/useCheckIfMobileSize';

const Wrapper = styled.div`
    display: grid;
    height: auto;

    position: relative;
    margin: auto;
    max-width: 1440px;
    width: 100%;

    ${breakpoints.xs} {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
            'content'
            'sidebar';
    }

    ${breakpoints.sm} {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
            'content'
            'sidebar';
    }

    ${breakpoints.md} {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
            'content'
            'sidebar';
    }

    ${breakpoints.md2} {
        position: static;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-areas: 'content content content sidebar sidebar';
        top: 72px;
    }

    ${breakpoints.lg} {
        position: static;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-areas: 'content content content sidebar sidebar';
        top: 72px;
    }
`;

const ContentWrapper = styled.article`
    grid-area: content;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${breakpoints.md2} {
        margin-top: 52px;
    }

    ${breakpoints.lg} {
        margin-top: 52px;
    }
`;

interface ContainerProps extends GettingStartedProps {
    children?: React.ReactNode;
    footer?: FooterProps;
}

export const Container: FC<ContainerProps> = ({ children, steps, footer }: ContainerProps) => {
    updateFooter({ show: true });
    const isMobileSize = useCheckIfMobileSize();

    return (
        <Wrapper>
            <ContentWrapper>
                {children}
                {Boolean(footer) && !isMobileSize && <Footer {...footer} />}
            </ContentWrapper>
            <GettingStarted steps={steps} />
            {Boolean(footer?.buttonRightGroup) && isMobileSize && (
                <FooterMobile buttonGroup={footer?.buttonRightGroup} />
            )}
        </Wrapper>
    );
};
