import React, { FC } from 'react';
import styled from 'styled-components';
import { UnorderedList, Text } from '@soluto-private/mx-asurion-ui-react-v3';

const Wrapper = styled.div`
    margin-left: 21px;
    margin-bottom: 48px;
`;

const items: string[] = ['Age of router', 'Router replacement'];

export const ImpactList: FC = () => (
    <Wrapper>
        <Text size={3} weight='heavy'>
            Things that may impact your speed:
        </Text>
        <UnorderedList hasIcons isSpacingCompact>
            {items.length &&
                items.map((item, index) => {
                    return (
                        <UnorderedList.ListItem
                            key={`support-list-item-${index}`}
                            iconColor='#EDB800'
                            iconSrc='SystemAlertWarning'
                        >
                            <React.Fragment key={`support-list-item-${index}.0`}>{item}</React.Fragment>
                        </UnorderedList.ListItem>
                    );
                })}
        </UnorderedList>
    </Wrapper>
);
