import React, { useState, VoidFunctionComponent } from 'react';
import { Welcome } from 'components/PageHeader/Welcome';
import styled from 'styled-components';
import { breakpoints, RadioButton } from '@soluto-private/mx-asurion-ui-react-v3';

const RouterSpeedTestQuestionPage: VoidFunctionComponent = () => {
    const [radioChecked, setRadioChecked] = useState('');

    const Wrapper = styled.div`
        display: block;
        padding: 16px;

        ${breakpoints.md} {
            padding: 0px 16px;
            margin-bottom: 48px;
        }

        ${breakpoints.md2} {
            padding: 0px 48px;
            margin-top: 46px;
        }

        ${breakpoints.lg} {
            padding: 0px 48px;
            margin-top: 46px;
        }
    `;

    const RadioButtonWrapper = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 48px 0px 0px;
        gap: 12px;

        > label {
            width: 100%;
            display: inline-flex;
            align-items: flex-start;
            box-sizing: border-box;
            text-align: left;
            line-height: 1.3;
            transition: box-shadow 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s;
        }
    `;

    const StyledRadioButton = styled(RadioButton)`
        padding: 1rem;
        color: rgb(0, 0, 0);
        background-color: rgb(255, 255, 255);
        border-radius: 8px;
        box-shadow: rgb(165, 170, 175) 0px 0px 0px 1px inset;
        text-align: center;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        transition: box-shadow 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s;
    `;
    return (
        <Wrapper data-testid='RouterSpeedTestQuestionPage_Container'>
            <Welcome
                pageTitle={'Do you want to test your router speed?'}
                pageSubTitle={
                    "Testing your router speed can determine if your router is working correctly and builds a baseline for your router's capacity."
                }
            />
            <RadioButtonWrapper role='radiogroup'>
                <StyledRadioButton
                    label='Yes'
                    name='runSpeedTest'
                    onChange={e => setRadioChecked(e.target.value)}
                    tabIndex={0}
                    value='yes'
                    checked={radioChecked === 'yes'}
                />
                <StyledRadioButton
                    label='No'
                    name='runSpeedTest'
                    onChange={e => setRadioChecked(e.target.value)}
                    tabIndex={0}
                    value='no'
                    checked={radioChecked === 'no'}
                />
            </RadioButtonWrapper>
        </Wrapper>
    );
};

export default RouterSpeedTestQuestionPage;
