import React, { VoidFunctionComponent } from 'react';
import { Welcome } from 'components/PageHeader/Welcome';
import { Room } from 'components/Room/Room';
import styled from 'styled-components';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react-v3';
import { room } from 'pages/landing/assets';

const LandingPage: VoidFunctionComponent = () => {
    const Wrapper = styled.div`
        gap: 48px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        ${breakpoints.xs} {
            padding: 0px 16px;
            margin-bottom: 48px;
        }

        ${breakpoints.sm} {
            padding: 0px 16px;
            margin-bottom: 48px;
        }

        ${breakpoints.md} {
            padding: 0px 16px;
            margin-bottom: 48px;
        }

        ${breakpoints.md2} {
            padding: 0px 48px;
            margin-top: 46px;
        }

        ${breakpoints.lg} {
            padding: 0px 48px;
            margin-top: 46px;
        }
    `;

    return (
        <Wrapper data-testid='HomePage_Container'>
            <Welcome
                pageTitle={"Welcome. Let's test your internet"}
                pageSubTitle='Move to the room you want to test, and select “Get started”'
            />
            <Room
                title='What room should I test?'
                subTitle={
                    "We recommend the room that's furthest from your router. You could also test a room with a lot of devices or where you connect with the internet the most."
                }
                image={room}
            />
        </Wrapper>
    );
};

export default LandingPage;
