import React, { VoidFunctionComponent, useState } from 'react';
import { Welcome } from 'components/PageHeader/Welcome';
import styled from 'styled-components';
import RouterSpeedTestContainer from './components/RouterSpeedTestContainer';

const RouterSpeedTest: VoidFunctionComponent = () => {
    const [isSpeedTestDone, setIsSpeedTestDone] = useState(false);
    const PageContainer = styled.div`
        height: auto;
        data-testid='RouterPage_Container'
    `;

    return (
        <PageContainer>
            {!isSpeedTestDone && (
                <Welcome
                    pageTitle={'Running the router test now...'}
                    pageSubTitle='Try not to move. The test may take up to 2 minutes.'
                />
            )}
            {isSpeedTestDone && (
                <Welcome
                    pageTitle={'Second test complete – one more step'}
                    pageSubTitle='Select “Retest” to start this test again.'
                />
            )}
            <RouterSpeedTestContainer
                onClick={() => setIsSpeedTestDone(!isSpeedTestDone)}
                isSpeedTestDone={isSpeedTestDone}
            />

            {/* comment out for scroll testing */}
            {Array.from(Array(100).keys()).map(i => (
                <p key={i}>{i}</p>
            ))}
        </PageContainer>
    );
};

export default RouterSpeedTest;
