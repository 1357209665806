import React, { FC } from 'react';
import styled from 'styled-components';
import { MainTitle } from 'components/Article/Results/ResultsOverviewTitle';
import { ResultsOverviewButtons } from './ResultsOverviewButtons';
import { SpeedResults } from './DeviceSpeedScore';
import { ImpactList, SupportList } from './List';

const Wrapper = styled.div``;

export const ResultsOverview: FC = () => (
  <Wrapper>
    <MainTitle />
    <ResultsOverviewButtons />
    <SpeedResults />
    <SupportList />
    <ImpactList />
  </Wrapper>
);
