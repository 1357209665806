import React, { useEffect } from 'react';
import AppRoutes from './routes';
import { updateHeader } from '@soluto-private/mx-app-header';
import { storage, Key } from '@soluto-private/mx-context';
import { initializeWixiSdk, wixiPartnerConfig } from './utils/NetworkScan';
import { GlobalStyles } from '@soluto-private/mx-asurion-ui-react-v3';
import { createGlobalStyle } from 'styled-components';

const CustomGlobalStyle = createGlobalStyle`
    #single-spa-application:\\@soluto-private\\/mx-app-footer > div > div {
        padding: 0px 0px 58px;

        @media (max-width: 1023px) {
            padding: 0px 16px 58px;
        }
    }
`;

storage.set(Key.Client, 'att'); // not sure the right way to set...

export const Root = () => {
  useEffect(() => {
    initializeWixiSdk(wixiPartnerConfig);
  }, []);

  // you can override this by adding updateHeader(HeaderState) to your page.
  updateHeader({
    view: 'service-view',
    menu: true,
    title: 'Title',
    subNavConfig: {
      title: 'SubNav Title',
      showBackButton: false,
      showPartnerLogo: true,
    },
  });

  return (
    <>
      <CustomGlobalStyle />
      <GlobalStyles />
      <AppRoutes />
    </>
  );
};

export default Root;
