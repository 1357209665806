import { Card, Icon, Text, breakpoints } from '@soluto-private/mx-asurion-ui-react-v3';
import React, { FC } from 'react';
import styled from 'styled-components';

const CustomCard = styled(Card)`
    border: 2px solid #37e7a7;
    border-radius: 12px;
    max-width: 471px;
    text-align: initial;
    padding 16px;
    margin-left: 21px;
    margin-bottom 48px;

    ${breakpoints.xs} {
        width: auto;
    }

    ${breakpoints.smOnly} {
        width: auto;
    }

    ${breakpoints.mdOnly || breakpoints.md2Only} {
        max-width: 471px;
    }
`;

const CustomText = styled(Text)`
    height: 26px;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    display: flex;
`;

const DownloadSpeedScores = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0px;
    gap: 20px 64px;

    max-width: 471px;
    height: auto;
    margin-top: 12px;
`;

const SpeedScore = styled.div`
    padding: 0px;
    max-height: 65px;
    width: auto;
    text-align: center;
`;

const SpeedTitle = styled.div`
    margin-bottom: 8px;

    span {
        font-weight: 700;
    }
`;

const Result = styled.div`
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;

    * {
        vertical-align: middle;
        margin-inline: 2.5px;
    }
`;

const IconBadge = styled(Icon)`
    background: #f0f0f5;
    padding: 3px 6px 3px 1px;
    border-radius: 50%;
`;

const ScoreDetail = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #999999;
`;

export const SpeedResults: FC = () => (
    <CustomCard
        disableSvgColorOverride
        element='div'
        maxWidth={473.13}
        onClick={() => {
            return;
        }}
        type='default'
    >
        <CustomText size={3}>Network speed results</CustomText>
        <DownloadSpeedScores>
            <SpeedScore>
                <SpeedTitle>
                    <Text>Router speed</Text>
                </SpeedTitle>
                <Result>
                    <IconBadge aria-hidden='true' fill='#000000' size='large' src='Router' title='router-speed' />
                    <Text size={5}>32</Text>
                    <ScoreDetail>Mbps</ScoreDetail>
                </Result>
            </SpeedScore>
            <SpeedScore>
                <SpeedTitle>
                    <Text>Download speed</Text>
                </SpeedTitle>
                <Result>
                    <IconBadge aria-hidden='true' fill='#000000' size='large' src='ArrowDown' title='download-speed' />
                    <Text size={5}>32</Text>
                    <ScoreDetail>Mbps</ScoreDetail>
                </Result>
            </SpeedScore>
            <SpeedScore>
                <SpeedTitle>
                    <Text>Upload speed</Text>
                </SpeedTitle>
                <Result>
                    <IconBadge aria-hidden='true' fill='#000000' size='large' src='ArrowUp' title='upload-speed' />
                    <Text size={5}>14</Text>
                    <ScoreDetail>Mbps</ScoreDetail>
                </Result>
            </SpeedScore>
        </DownloadSpeedScores>
    </CustomCard>
);
