import React, { FC } from 'react';
import styled from 'styled-components';
import { breakpoints, Button, ButtonGroup } from '@soluto-private/mx-asurion-ui-react-v3';

const Wrapper = styled.div`
    margin: 8px 20px 48px;
    max-width: 749;

    ${breakpoints.xs} {
        width: auto;
        margin: 16px 0 68px 5px;
        font-size: 32px;
    }

    ${breakpoints.smOnly} {
        width: auto;
        margin: 16px 0 68px 5px;
    }

    ${breakpoints.mdOnly} {
        width: auto;
        margin: 16px 0 68px 30px;
    }}

    ${breakpoints.md2Only} {
        margin: 8px auto 48px;
        max-width: 749px;
    }
`;

const CustomButton = styled(Button)`
    ${breakpoints.xs} {
        width: 100%;
    }

    ${breakpoints.smOnly} {
        width: 100%;
    }

    ${breakpoints.mdOnly} {
        width: 100%;
    }

    ${breakpoints.md2Only || breakpoints.lgOnly} {
        with: auto;
        margin: 16px 0;
    }
`;

const CustomButtonGroup = styled(ButtonGroup)`
    gap: 1rem;
`;

export const ResultsOverviewButtons: FC = () => (
    <Wrapper>
        <CustomButtonGroup alignment='start'>
            <CustomButton
                color='secondary'
                iconSide='left'
                iconSrc='ChatBubbleCircle'
                onClick={() => {
                    return;
                }}
                variant='outline'
            >
                Chat with an expert
            </CustomButton>
            <CustomButton
                color='secondary'
                iconSide='left'
                iconSrc='Phone'
                onClick={() => {
                    return;
                }}
            >
                Call: 800-111-2222
            </CustomButton>
        </CustomButtonGroup>
    </Wrapper>
);
