import React, { ReactNode } from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

import Home from './pages/home';
import Profile from './pages/profile';
import RouterScan from './pages/routerScan';
import NetworkScan from './pages/NetworkScan';
import { Question1, Question2 } from './pages/questionnaires';
import ResultsOverviewPage from './pages/ResultsOverview';

import { RoutePath } from './RoutePath';
import RouterSpeedTestQuestion from './pages/routerSpeedTestQuestion';
import RouterSpeedTestIntroduction from './pages/routerSpeedTestIntroduction';

const privateRoute = (child: ReactNode) => (
  <ProtectedRoute>{child}</ProtectedRoute>
);

const AppRoutes = () => (
  <Routes>
    <Route path={RoutePath.Home} element={<Home />} />
    <Route path={RoutePath.NetworkScan} element={<NetworkScan />} />
    <Route path={RoutePath.Profile} element={privateRoute(<Profile />)} />
    <Route path={RoutePath.RouterTest} element={<RouterScan />} />
    <Route path={RoutePath.Question1} element={<Question1 />} />
    <Route path={RoutePath.Question2} element={<Question2 />} />
    <Route path={RoutePath.ResultsOverview} element={<ResultsOverviewPage />} />
    <Route
      path={RoutePath.RouterQuestion}
      element={<RouterSpeedTestQuestion />}
    />
    <Route
      path={RoutePath.RouterIntroduction}
      element={<RouterSpeedTestIntroduction />}
    />
  </Routes>
);

export default AppRoutes;
