import React, { FC } from 'react';
import { Container } from 'components/Main';
import { ResultsOverview } from 'components/Article';

const ResultsOverviewPage: FC = () => {
  return (
    <Container steps={['complete', 'inprogress', 'incomplete']}>
      <ResultsOverview />
    </Container>
  );
};

export default ResultsOverviewPage;
