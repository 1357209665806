import React, { useState, VoidFunctionComponent } from 'react';
import { Container } from 'components/Main';
import { NetworkScanInProgress } from 'pages/NetworkScan/components';
import { NetworkScanComplete } from 'pages/NetworkScan/components';
import { Button } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import { updateHeader } from '@soluto-private/mx-app-header';
import { useNavigate } from 'react-router-dom';
import { NetworkScanningProvider } from 'components/NetworkScanning/NetworkScanningProvider';

const NetworkScan: VoidFunctionComponent = () => {
  const navigate = useNavigate();
  updateHeader({
    view: 'service-view',
    menu: true,
    onBack: () => navigate(-1), // onBack func not working...
    subNavConfig: {
      showBackButton: true,
      showPartnerLogo: true,
    },
  });

  const MainContainer = styled.div`
    display: grid;
    grid-template-rows: 1fr auto;
  `;

  const [isScanComplete, setIsScanComplete] = useState(false);

  const buttonRightGroup = (
    <Button variant="outline" color="secondary">
      Cancel
    </Button>
  );

  return (
    <NetworkScanningProvider>
      <MainContainer>
        <Container
          steps={['complete', 'inprogress', 'incomplete']}
          footer={{
            onBack: true,
            buttonRightGroup,
          }}
        >
          <div onClick={() => setIsScanComplete(!isScanComplete)}>
            {isScanComplete && <NetworkScanInProgress />}
            {!isScanComplete && <NetworkScanComplete />}
          </div>
        </Container>
      </MainContainer>
    </NetworkScanningProvider>
  );
};

export default NetworkScan;
