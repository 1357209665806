import React, { FC } from 'react';
import styled from 'styled-components';
import { breakpoints, Tag, Text } from '@soluto-private/mx-asurion-ui-react-v3';

const Title = styled.div`
  font-weight: 300;
  font-size: 40px;
  line-height: 115%;
  color: #000000;
  margin: 0 20px 8px;
  max-width: 749px;

  ${breakpoints.xs} {
    width: auto;
    margin: 16px 0 24px 5px;
    font-size: 32px;
  }

  ${breakpoints.smOnly} {
    width: auto;
    margin: 16px 0 24px 5px;
  }

  ${breakpoints.mdOnly} {
    width: auto;
    margin: 16px 0 24px 30px;
  }

  ${breakpoints.md2Only} {
    margin: 0 auto 8px;
    max-width: 749px;
  }
`;

const SubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: #333f48;
  margin: 8px 0 28px;

  div {
    display: inline-block;
  }
`;

const CustomTag = styled(Tag)`
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  gap: 2px;
  border-radius: 2px;
  line-height: 1.5rem;
  padding-bottom: 1px;
`;

export const MainTitle: FC = () => (
  <Title>
    <Text as="h2" size={6} weight="feather">
      The results are in — we’re help to help
    </Text>
    <SubTitle>
      <Text as="h4" size={4} color="#333f48">
        We want to take extra time to walk you through your results and guide
        you the best way we can. Call or chat with us and give the expert your
        code:&nbsp;
        <div>
          <CustomTag size="regular" text="SK902" type="light" />
        </div>
      </Text>
    </SubTitle>
  </Title>
);
