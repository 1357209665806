import React from 'react';
import { SpeedResult } from './SpeedResult';
import { Welcome } from 'components/PageHeader/Welcome';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import { HighFive } from 'components/common';
import { useNetworkScanContext } from 'components/NetworkScanning/NetworkScanningProvider';

export const NetworkScanComplete = () => {
  const Container = styled.div`
    margin-top: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;

    ${breakpoints.sm} {
      margin-top: 96px;
    }
  `;

  const Image = styled.img`
    width: 144px;
    height: 144px;
    margin-bottom: 52px;
  `;

  const SpeedContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;

    ${breakpoints.md} {
      width: 66%;
    }
  `;

  const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: scroll;
    padding: 0px 16px;

    ${breakpoints.xs} {
      margin-bottom: 216px;
    }

    ${breakpoints.sm} {
      margin-bottom: 128px;
    }

    ${breakpoints.md} {
      margin-bottom: 48px;
    }

    ${breakpoints.md2} {
      padding: 0px 48px;
      margin-top: 48px;
    }
  `;

  const { downloadSpeed, uploadSpeed } = useNetworkScanContext();

  return (
    <Wrapper>
      <Welcome
        pageTitle="Test complete &#8212; let's keep going"
        pageSubTitle='Select "Retest" to start this test again'
      />
      <Container>
        <Image src={HighFive} />
        <SpeedContainer>
          <SpeedResult
            title="Download speed"
            speedValue={downloadSpeed || '100'}
            isLoading={false}
            variant="download"
          />
          <SpeedResult
            title="Upload speed"
            speedValue={uploadSpeed || '30'}
            isLoading={false}
            variant="upload"
          />
        </SpeedContainer>
      </Container>
    </Wrapper>
  );
};
