import React, { VoidFunctionComponent } from 'react';
import styled from 'styled-components';
import { Icon, Text } from '@soluto-private/mx-asurion-ui-react-v3';

interface SpeedResultProps {
    title: string;
    speedValue: string;
    variant: 'download' | 'upload';
    isLoading: boolean;
}

export const SpeedResult: VoidFunctionComponent<SpeedResultProps> = ({ title, speedValue, isLoading }) => {
    const Container = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `;

    const SpeedContainer = styled.div`
        display: flex;
        flex-direction: row;
        margin-top: 12px;
        align-items: center;
    `;

    type ButtonContainerProps = {
        isLoading: boolean;
    };

    const SpeedIconContainer = styled.div<ButtonContainerProps>`
        background: rgb(0, 0, 0, 0.1);
        border-radius: 50%;
        opacity: ${props => (props.isLoading ? '0.25' : '1')};
    `;

    const EmDash = styled.span`
        opacity: 0.25;
    `;

    return (
        <Container>
            <Text size={1} weight='heavy'>
                {title}
            </Text>
            <SpeedContainer>
                <SpeedIconContainer isLoading={isLoading}>
                    <Icon src='ArrowUp' size='xlarge' />
                </SpeedIconContainer>
                <Text size={6} weight='base'>
                    {isLoading ? <EmDash>&#8212;</EmDash> : speedValue}
                </Text>
                <Text size={2} weight='feather' color='#999999'>
                    {' Mbps'}
                </Text>
            </SpeedContainer>
        </Container>
    );
};
