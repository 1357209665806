import React, { useEffect, VoidFunctionComponent } from 'react';
import { SpeedProgress } from './SpeedProgress';
import { SpeedResult } from './SpeedResult';
import { Welcome } from 'components/PageHeader/Welcome';
import styled from 'styled-components';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react-v3';
import { useNetworkScanContext } from 'components/NetworkScanning/NetworkScanningProvider';
import { formatSpeed } from 'utils/NetworkScan';

export const NetworkScanInProgress: VoidFunctionComponent = () => {
    const Container = styled.div`
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        ${breakpoints.sm} {
            margin-top: 80px;
            margin-bottom: 16px;
        }
    `;

    const SpeedContainer = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;

        ${breakpoints.md} {
            width: 66%;
        }
    `;

    const Wrapper = styled.div`
        display: flex;
        flex-direction: column;
        gap: 12px;
        overflow-y: scroll;
        padding: 0px 16px;
        margin-bottom: 48px;

        ${breakpoints.md2} {
            padding: 0px 48px;
            margin-top: 48px;
        }
    `;

    const { startSpeedTest, downloadSpeed, uploadSpeed } = useNetworkScanContext();

    useEffect(() => {
        startSpeedTest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <Welcome
                pageTitle={'Testing your network now...'}
                pageSubTitle='Try not to move. The test may take up to 2 minutes.'
            />
            <Container>
                <SpeedProgress progressData={{ speed: formatSpeed(downloadSpeed), status: 'Testing download speed' }} />
                <SpeedContainer>
                    <SpeedResult title='Download speed' speedValue={downloadSpeed} isLoading variant='download' />
                    <SpeedResult title='Upload speed' speedValue={uploadSpeed} isLoading variant='upload' />
                </SpeedContainer>
            </Container>
        </Wrapper>
    );
};
