import React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react-v3';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react-v3';

interface SpeedTestProgress {
    speed: number;
    status: string;
}

interface SpeedProgressProps {
    progressData: SpeedTestProgress;
}

const styles = {
    rotation: 1 / 2 + 1 / 5,
    strokeLinecap: 'butt',
    trailColor: '#eee',
};

const SpeedProgressCircle = styled.div`
    max-width: 450px;

    width: 272px;
    height: 190px;

    path {
        stroke-width: 4;
    }

    @media all and (max-width: 450px) {
        width: 300px;
    }

    .CircularProgressbar-path {
        stroke: black !important;
    }
`;

const SpeedTestProgressContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    max-width: 290px;
    margin-bottom: 40px;
`;

const CircleNumbers = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #999999;
    max-width: 272px;
    width: 100%;
    font-size: 16px;
    margin-top: 8px;

    ${breakpoints.md} {
        max-width: 248px;
        width: 100%;
        font-size: 13px;
        margin-top: -8px;
    }
`;

const Box = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -50px;
`;

const CurrentSpeedLabel = styled.span`
    font-size: 48px;
`;

const StatusLabel = styled(Text)`
    margin-top: -16px;
`;

export const SpeedProgress = ({ progressData }: SpeedProgressProps) => {
    const { speed, status } = progressData;
    const SPEED_THRESHOLD = 250;
    const speedValue = speed > SPEED_THRESHOLD ? (speed / SPEED_THRESHOLD) * 100 : speed;

    return (
        <SpeedTestProgressContainer>
            <SpeedProgressCircle>
                <CircularProgressbarWithChildren
                    value={speedValue} // the actual progress
                    circleRatio={0.6}
                    styles={buildStyles(styles)}
                >
                    <Box>
                        <CurrentSpeedLabel>{speed}</CurrentSpeedLabel>
                        <Text size={1} weight='feather' color='#999999'>
                            Mbps
                        </Text>
                    </Box>
                </CircularProgressbarWithChildren>
            </SpeedProgressCircle>
            <CircleNumbers>
                <div>0</div>
                {speed > SPEED_THRESHOLD ? <div>250</div> : <div>100</div>}
            </CircleNumbers>
            <StatusLabel size={2} weight='feather'>
                {status}
            </StatusLabel>
        </SpeedTestProgressContainer>
    );
};
