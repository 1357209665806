import React, { VoidFunctionComponent } from 'react';
import styled from 'styled-components';
import { Text, Icon } from '@soluto-private/mx-asurion-ui-react-v3';

const ResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 19px;
    order: 1;
`;

const ResultHeaderDiv = styled.div`
    width: 100%;
    text-align: center;
`;

const ResultsContentDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

const RouterIcon = styled(Icon)`
    background: #f0f0f5;
    padding: 2px;
    border-radius: 50%;
`;

const RouterTestResults: VoidFunctionComponent = () => (
    <ResultsContainer>
        <ResultHeaderDiv>
            <Text size={2} weight='heavy'>
                Router Speed
            </Text>
        </ResultHeaderDiv>
        <ResultsContentDiv>
            <RouterIcon fill='#000000' size='xlarge' src='Router' />
            <Text size={6} weight='base'>
                32
            </Text>
            <Text size={3} weight='base' color='#999999'>
                Mbps
            </Text>
        </ResultsContentDiv>
    </ResultsContainer>
);

export default RouterTestResults;
