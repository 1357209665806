import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from 'react';
import {
  SpeedTestStatus,
  SpeedtestType,
  WixiSnap,
  WixiCore,
} from '@soluto-private/wixi-web-sdk';
import { IScanResults, WebScanState } from 'types/NetworkScan';
import {
  useSpeedTestStatus,
  useSpeedTestDownloadSpeed,
  useWixiSession,
  useSpeedTestUploadSpeed,
} from 'hooks/NetworkScan';

export type NetworkScanContextType = {
  startSpeedTest: () => void;
  cancelSpeedTest: () => void;
  downloadSpeed: string;
  uploadSpeed: string;
  scanStatus: SpeedTestStatus;
  webScanState: WebScanState;
  speedTestResults?: IScanResults;
};

const defaultNetworkScanContext: NetworkScanContextType = {
  startSpeedTest: () => undefined,
  cancelSpeedTest: () => undefined,
  downloadSpeed: '',
  uploadSpeed: '',
  scanStatus: SpeedTestStatus.none,
  webScanState: WebScanState.NOT_STARTED,
  speedTestResults: undefined,
};

const NetworkScanContext = createContext<NetworkScanContextType>(
  defaultNetworkScanContext
);

export const useNetworkScanContext = () => useContext(NetworkScanContext);

interface Props {
  children: ReactNode;
}

export const NetworkScanningProvider = ({ children }: Props) => {
  const [webScanState, setWebScanState] = useState<WebScanState>(
    WebScanState.NOT_STARTED
  );
  const wixiSession = useWixiSession();
  const downloadSpeed = useSpeedTestDownloadSpeed();
  const uploadSpeed = useSpeedTestUploadSpeed();

  const [wixiSnap] = useState<WixiSnap>(
    new WixiSnap({
      wixiHealthConfig: { speedtestType: SpeedtestType.UPLOAD_AND_DOWNLOAD },
    })
  );

  const onSnapStarted = useCallback(() => {
    setWebScanState(WebScanState.INITIALIZING);
  }, []);

  const startSpeedTest = useCallback(async () => {
    setWebScanState(WebScanState.INITIALIZING);
    wixiSnap.onSnapStart = onSnapStarted;
    await wixiSnap?.snap();
  }, [wixiSnap, onSnapStarted]);
  const speedTestStatus = useSpeedTestStatus();
  const [scanStatus, setScanStatus] =
    useState<SpeedTestStatus>(speedTestStatus);
  const scanCompleted = scanStatus === SpeedTestStatus.completed;

  const [speedTestResults, setSpeedTestResults] = useState<IScanResults>({
    clientId: '',
    goId: '',
    downloadSpeed: '',
  });

  useEffect(() => {
    setScanStatus(speedTestStatus);
  }, [speedTestStatus]);

  useEffect(() => {
    if (scanCompleted) {
      setWebScanState(WebScanState.FINISHED);
      const results: IScanResults = {
        clientId: WixiCore.clientId,
        goId: wixiSession.goId || '',
        downloadSpeed,
      };

      setSpeedTestResults(results);
    }
  }, [downloadSpeed, scanCompleted, wixiSession.goId]);

  const cancelSpeedTest = useCallback(() => {
    wixiSnap?.cancel();
    setWebScanState(WebScanState.NOT_STARTED);
  }, [wixiSnap]);

  const contextValues: NetworkScanContextType = {
    startSpeedTest,
    cancelSpeedTest,
    scanStatus,
    downloadSpeed,
    uploadSpeed,
    webScanState,
    speedTestResults,
  };

  return (
    <NetworkScanContext.Provider value={contextValues}>
      {children}{' '}
    </NetworkScanContext.Provider>
  );
};
