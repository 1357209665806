export enum RoutePath {
    Home = '/',
    NetworkScan = '/network-scan',
    Profile = '/profile',
    Question1 = '/question-1',
    Question2 = '/question-2',
    RouterIntroduction = '/router-scan',
    RouterQuestion = '/router-scan/confirm',
    RouterTest = '/router-scan/scan',
    ResultsOverview = '/results-overview',
}
